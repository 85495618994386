import { FileResponse } from "@/interfaces/upload";

export class FileResponseEntity implements FileResponse {
	uploaded: Boolean = false;
	isValid: Boolean = false;
	messages: string[] = [];

	constructor(parameters?: FileResponse) {
		this.setData(parameters);
	}

	setData(parameters?: FileResponse) {
		if (parameters) {
			this.uploaded = parameters.uploaded;
			this.isValid = parameters.isValid;
			this.messages = parameters.messages;
		}
	}
}
