import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardActions from "@/components/Content/CardAction.vue";
import FormPrivatePois from "@/views/Admin/PrivatePois/Form/index.vue";
import TablePrivatePois from "@/views/Admin/PrivatePois/Table/index.vue";

import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isMaxCustomLength,
} from "@/services/rule-services";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

export default Vue.extend({
	name: "PrivatePoisCreate",
	props: {},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		CardActions,
		FormPrivatePois,
		TablePrivatePois,
	},
	data: () => ({
		valid: true,
	}),
	created() {},
	mounted() {},
	computed: {
		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isMaxCustomLength,
			};
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async validate() {
			return await this.$refs.form.validate();
		},
		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);

				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},
		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
			}
		},
	},
	watch: {},
});
